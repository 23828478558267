import { NavigateToHome } from "../components/navigateToHome";
import { Videos } from "../components/videos/videos";

export function PageVideoEditing() {
    return (
        <div className="page">
            <h1>Edição de Vídeo</h1>
            <NavigateToHome />

            <Videos />

            <NavigateToHome />
        </div>
    );
}