import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";

export interface GalleryProps {
    items: ReactImageGalleryItem[]
}

export function Gallery({ items }: GalleryProps) {

    return (<ImageGallery items={items} />);
}