import { Reel } from "../components/reel";
import { Link } from 'react-router-dom';

export function PageHome() {
    return (
        <div className="home">

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                }}>

                <div
                    style={{
                        flexGrow: 1
                    }}>
                    <h1
                        style={{
                            marginTop: "0rem",
                            paddingTop: "3rem",
                            textTransform: "uppercase",
                        }}>
                        Teresa Sousa
                    </h1>

                    <div
                        style={{
                            marginBlock: "3rem",
                            marginInline: "auto",
                            display: "flex",
                            justifyContent: "center",
                        }}>
                        <Reel />
                    </div>

                    <div
                        style={{
                            maxWidth: "500px",
                            display: "flex",
                            flexDirection: "column",
                            margin: "auto",
                            marginBlock: "3rem",
                            gap: "1rem",
                            padding: "1rem",
                        }}>

                        <Link className="home-link" to={"/illustrations"}><div className="home-link-text">Ilustração</div></Link>
                        <Link className="home-link" to={"/storyboards"}><div className="home-link-text">Storyboards</div></Link>
                        <Link className="home-link" to={"/photos"}><div className="home-link-text">Fotografia</div></Link>
                        <Link className="home-link" to={"/exhibitions"}><div className="home-link-text">Exposições</div></Link>
                        <Link className="home-link" to={"/zines"}><div className="home-link-text">Zines</div></Link>
                        <Link className="home-link" to={"/video-editing"}><div className="home-link-text">Edição de Vídeo</div></Link>
                        <Link className="home-link" to={"/stopmotion"}><div className="home-link-text">Stop Motion</div></Link>

                    </div>
                </div>

                <div style={{
                    backgroundColor: "#7A8C79",
                }}>
                    <div className="footer">
                        <div className="footerText">tresasousa@gmail.com</div>
                        <div className="footerText">932 803 590</div>
                        <a href="/documents/Teresa-Sousa-2024.pdf" download>
                            <div className="footerText footerLink">
                                Transferir CV
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div >
    );
}
