export interface ExhibitionProps {
    title: string;
    description1: string;
    description2: string;
    date: string;
}

export function Exhibition({ title, description1, description2, date }: ExhibitionProps) {

    return (
        <div className="exhibition">

            <div className="exhibition-content">
                <div
                    style={{ fontWeight: "500" }}>
                    {title}
                </div>
                <div>
                    {description1}
                </div>
                <div>
                    {description2}
                </div>
                <div>
                    {date}
                </div>
            </div>


        </div >
    )
}