import { Photo } from "../photo";

export function Zines() {
    return (
        <>
            <p style={{
                marginBottom: "3rem",
                padding: "1rem"
            }}>
                Dez estórias e ilustrações em duas zines sobre pessoas que implodem e pessoas que se explodem.
            </p>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    margin: "auto",
                    maxWidth: "800px"
                }}>
                <Photo imagePath="zines/zine01.jpg" />
                <Photo imagePath="zines/zine02.jpg" />
                <Photo imagePath="zines/zine03.jpg" />
            </div>
        </>
    );
}