import { YoutubeVideo } from "./youtubeVideo";

export function Reel() {

    return (
        <div
            style={{
                width: "100%",
            }}
        >
            <YoutubeVideo videoId="oSuaaW3tOYk" />
        </div>
    )
}