import { Exhibitions } from "../components/exhibitions/exhibitions";
import { NavigateToHome } from "../components/navigateToHome";

export function PageExhibitions() {
    return (
        <div className="page">
            <h1>Exposições</h1>

            <NavigateToHome />

            <Exhibitions />

            <NavigateToHome />
        </div>
    );
}