import { BrowserRouter as Router } from 'react-router-dom';

import { SiteRoutes } from './siteRoutes';

export function AppRouter() {
    return (
        <Router>
            <SiteRoutes />
        </Router>
    );
}
