import { Route, Routes } from 'react-router-dom';

import { PageHome } from './pages/pageHome';

import { PageIllustrations } from './pages/pageIllustrations';
import { PageNotFound } from './pages/pageNotFound';
import { PageStoryboards } from './pages/pageStoryboards';
import { PagePhotos } from './pages/pagePhotos';
import { PageExhibitions } from './pages/pageExhibitions';
import { PageZines } from './pages/pageZines';
import { PageVideoEditing } from './pages/pageVideoEditing';
import { PageStopmotion } from './pages/pageStopmotion';

export function SiteRoutes() {

    return (
        <Routes>
            <Route path="/" Component={PageHome} />
            <Route path="/illustrations" Component={PageIllustrations} />
            <Route path="/storyboards" Component={PageStoryboards} />
            <Route path="/photos" Component={PagePhotos} />
            <Route path="/exhibitions" Component={PageExhibitions} />
            <Route path="/zines" Component={PageZines} />
            <Route path="/video-editing" Component={PageVideoEditing} />
            <Route path="/stopmotion" Component={PageStopmotion} />
            <Route path="*" Component={PageNotFound} />
        </Routes>
    )
}