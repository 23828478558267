import { Illustration } from "../components/illustration";
import { NavigateToHome } from "../components/navigateToHome";

export function PageIllustrations() {
    return (
        <div className="page">
            <h1>Ilustração</h1>

            <NavigateToHome />

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3rem",
                    margin: "auto",
                    maxWidth: "800px"
                }}
            >
                <Illustration imagePath="/illustrations/illustration01.png" />
                <Illustration imagePath="/illustrations/illustration02.png" />
                <Illustration imagePath="/illustrations/illustration03.png" />
                <Illustration imagePath="/illustrations/illustration04.png" />
                <Illustration imagePath="/illustrations/illustration05.png" />
                <Illustration imagePath="/illustrations/illustration06.png" />
                <Illustration imagePath="/illustrations/illustration07.jpg" />
                <Illustration imagePath="/illustrations/illustration08.png" />
                <Illustration imagePath="/illustrations/illustration09.png" />
                <Illustration imagePath="/illustrations/illustration10.jpeg" />
                <Illustration imagePath="/illustrations/illustration12.png" />
                <Illustration imagePath="/illustrations/illustration13.jpg" />
            </div>

            <NavigateToHome />
        </div>
    );
}