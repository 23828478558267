import { Gallery } from "../gallery";

export function ExhibitionQuadradinhos() {
    return (

        <div
            style={{ maxWidth: "800px", paddingTop: "2rem" }}>

            <Gallery items={[
                {
                    original: "exhibitions/quadradinhos/quadradinhos02.jpg",
                    thumbnail: "exhibitions/quadradinhos/quadradinhos02.jpg",
                    description: "Quadradinhos"
                },
                {
                    original: "exhibitions/quadradinhos/quadradinhos01.jpg",
                    thumbnail: "exhibitions/quadradinhos/quadradinhos01.jpg",
                    description: "Quadradinhos"
                },
            ]} />
        </div>
    )
}