
export interface IllustrationProps {
    imagePath: string;
}

export function Illustration({ imagePath }: IllustrationProps) {

    return (
        <img className="illustration"
            src={imagePath}
            alt="Illustration"
        />
    );
}