import { YoutubeShort } from "../youtubeShort";

export function Videos() {
    return (
        <>
            <div
                style={{
                    marginBottom: "3rem",
                    padding: "1rem"
                }}>
                <div>
                    2022 - Vídeos promocionais para o grupo de música infantil Bicho de 7 Cabeças
                </div>
                <div>
                    Estágio profissional IEFP do curso Técnico de Vídeo
                </div>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                    flexWrap: "wrap",
                    maxWidth: "800px",
                    margin: "auto",
                    justifyContent: "center"
                }}>

                <YoutubeShort videoId="zwV-4TVYNc0" />
                <YoutubeShort videoId="a94Vqk-isG0" />
                <YoutubeShort videoId="6BiKzaqM8RA" />
                <YoutubeShort videoId="Igs3RGHXqcw" />
                <YoutubeShort videoId="dQwEOVSkNbA" />
                <YoutubeShort videoId="hf5brL-SbjU" />

            </div>
        </>
    );
}