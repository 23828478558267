
export interface PhotoProps {
    imagePath: string;
}

export function Photo({ imagePath }: PhotoProps) {

    return (
        <img
            src={imagePath}
            alt="Photograph"
            style={{
                maxWidth: "800px",
                objectFit: "contain",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
            }}
        />
    );
}