import { Illustration } from "../components/illustration";
import { NavigateToHome } from "../components/navigateToHome";

export function PageStoryboards() {
    return (
        <div className="page">
            <h1>Storyboards</h1>

            <NavigateToHome />

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3rem",
                }}
            >
                <div>
                    <h2>2024 - Contraponto</h2>
                    <div
                        style={{
                            display: "flex",
                            gap: "1rem",
                            flexDirection: "column",
                            maxWidth: "800px",
                            margin: "auto"
                        }}>
                        <Illustration imagePath="/storyboards/storyboard01.png" />
                        <Illustration imagePath="/storyboards/storyboard03.png" />
                    </div>

                </div>

                <div>
                    <h2>2022 - No Céu como na Terra</h2>
                    <Illustration imagePath="/storyboards/storyboard02.jpg" />
                </div>

                <div>
                    <h2>2007 - Freddy</h2>
                    <Illustration imagePath="/storyboards/storyboard04.png" />
                </div>

                <div>
                    <h2>2007 - Nightmare Polaroid</h2>
                    <Illustration imagePath="/storyboards/storyboard05.png" />
                </div>
            </div>

            <NavigateToHome />
        </div>
    );
}