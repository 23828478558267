import { Photo } from "../photo";


export function Stopmotion() {
    return (
        <div>
            <div style={{
                marginBottom: "3rem"
            }}>
                2024 - Marionetas de teste para o projecto de final de curso de Animação 2D e 3D pelo IEFP
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    margin: "auto",
                    maxWidth: "800px"
                }}>
                <Photo imagePath="stopmotion/stopmotion01.jpg" />
                <Photo imagePath="stopmotion/stopmotion02.jpeg" />
            </div>

        </div>
    )
}