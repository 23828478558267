import { NavigateToHome } from "../components/navigateToHome";
import { Stopmotion } from "../components/stopmotion/stopmotion";

export function PageStopmotion() {
    return (
        <div className="page">
            <h1>Stopmotion</h1>
            <NavigateToHome />

            <Stopmotion />

            <NavigateToHome />
        </div>
    );
}