
export interface YoutubeVideoProps {
    videoId: string;
}

export function YoutubeVideo({ videoId }: YoutubeVideoProps) {

    const width = 560;
    const w = `min(90%, ${width}px)`;

    return (
        <iframe
            style={{
                width: w,
                aspectRatio: "16 / 9"
            }}
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>

    );
}