import { Exhibition } from "./exhibition";
import { ExhibitionQuadradinhos } from "./exhibitionQuadradinhos";

export function Exhibitions() {
    return (
        <div style={{
            maxWidth: "800px",
            margin: "auto"
        }}>
            <Exhibition
                title="Quadradinhos, Histórias de BD em 'post it' amarelos"
                description1="Espaço Yron, Lisboa"
                description2="Exposição coletiva"
                date="2008"
            />

            <Exhibition
                title="Kinda Kinky"
                description1="Clínica Contemporânea, Lisboa"
                description2=""
                date="2006"
            />

            <Exhibition
                title="Arte Urbana para Intervenção"
                description1="Comissionada pelo grupo Zart21, Lisboa (colectiva)"
                description2=""
                date="2005"
            />

            <ExhibitionQuadradinhos />

        </div>
    )
}