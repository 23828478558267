import { NavigateToHome } from "../components/navigateToHome";
import { Photo } from "../components/photo";

export function PagePhotos() {
    return (
        <div className="page">
            <h1>Fotografia</h1>
            <NavigateToHome />
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    margin: "auto",
                    maxWidth: "800px"
                }}
            >
                <Photo imagePath="/photos/photo01.jpg" />
                <Photo imagePath="/photos/photo02.jpg" />
                <Photo imagePath="/photos/photo03.jpg" />
                <Photo imagePath="/photos/photo04.jpg" />
                <Photo imagePath="/photos/photo05.jpg" />
                <Photo imagePath="/photos/photo06.jpg" />
                <Photo imagePath="/photos/photo07.jpg" />
                <Photo imagePath="/photos/photo08.jpg" />
                <Photo imagePath="/photos/photo09.jpg" />
                <Photo imagePath="/photos/photo10.jpg" />
                <Photo imagePath="/photos/photo11.jpg" />
                <Photo imagePath="/photos/photo12.jpg" />
                <Photo imagePath="/photos/photo13.jpg" />
                <Photo imagePath="/photos/photo14.jpg" />
                <Photo imagePath="/photos/photo15.jpg" />
                <Photo imagePath="/photos/photo16.jpg" />
                <Photo imagePath="/photos/photo17.jpg" />
            </div>
            <NavigateToHome />
        </div>
    );
}