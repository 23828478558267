import { NavigateToHome } from "../components/navigateToHome";
import { Zines } from "../components/zines/zines";

export function PageZines() {
    return (
        <div className="page">
            <h1>Zines</h1>

            <NavigateToHome />

            <Zines />

            <NavigateToHome />
        </div>
    );
}