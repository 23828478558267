
export interface YoutubeShortProps {
    videoId: string
}

export function YoutubeShort({ videoId }: YoutubeShortProps) {

    const height = 560;
    const width = 315;

    const w = `max(100%, ${width}px)`;

    return (

        <iframe
            width={w}
            height={height}
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>

    );
}